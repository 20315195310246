<template>
    <b-row class="match-height">
      <b-col lg="12">
        <b-card title="PO Summary Detail">
          <b-row>
            <b-col cols="12">
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="previous()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="align-middle"
                />
                Back
              </b-button>
              <div class="devider" />
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <br />
                <div >
                  <b-row>
                    <b-col cols="2" class="text-center font-weight-bold">
                      No
                    </b-col>
                    <b-col cols="10" class="text-center font-weight-bold">
                      Purchase Order Number
                    </b-col>
                  </b-row>
                  <p />
                  <b-row
  v-for="(item, index) in items"
  :key="index"
  class="mb-2"
>
  <b-col cols="2" class="text-center">
    <b-list-group>
      <b-list-group-item href="#">
        {{ Number(index) + 1 }}
      </b-list-group-item>
    </b-list-group>
  </b-col>

  <b-col cols="10" class="text-center">
    <b-list-group>
      <b-list-group-item href="#">
        {{ item }}
      </b-list-group-item>
    </b-list-group>
  </b-col>
</b-row>
                </div>
              </b-form-group>

            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BListGroup,
    BListGroupItem,
  } from "bootstrap-vue"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import axios from "axios"
  import moment from "moment"
  import Ripple from "vue-ripple-directive"
  import { VueHorizontalTimeline } from "vue-horizontal-timeline"
  import { ref } from "@vue/composition-api"

  const VersionCheck = ""
  const items = []
  const chosenItem = ref(3)
  const chosens = []
  const statusItems = []
  const supplier = ""
  const shipmenBy = ""
  const deliveryAddress = ""
  const purchaseOrderSchedule = ""
  const billingAddress = ""
  const termOfPayment = ""
  const paymentType = ""
  const number = ""
  const type = ""
  const purchaseOrderIsConfirmed = false
  const purchaseOrderByPurchasingIsConfirmed = false
  const purchaseOrderByWarehouseIsConfirmed = false
  const purchaseOrderIsConfirmedByPresdir = false
  const purchaseOrderLink = ""
  const purchaseOrderDiscountPercentage = ""
  const purchaseOrderWithTax = ""
  const totalCharge = 0
  const currency = ''
  const isWithTax = false
  const taxPercentage = 0
  const taxPercentageReal = 0
  const transportFee = 0
  const totalTax = 0
  const grandTotal = 0
  export default {
    components: {
      VueHorizontalTimeline,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        purchaseOrderIsConfirmedByPresdir,
        transportFee,
        taxPercentageReal,
        grandTotal,
        isWithTax,
        taxPercentage,
        totalTax,
        currency,
        totalCharge,
        purchaseOrderWithTax,
        number,
        purchaseOrderIsConfirmed,
        type,
        purchaseOrderLink,
        purchaseOrderDiscountPercentage,
        purchaseOrderByPurchasingIsConfirmed,
        purchaseOrderByWarehouseIsConfirmed,
        paymentType,
        statusItems,
        VersionCheck,
        items,
        chosenItem,
        chosens,
        supplier,
        shipmenBy,
        deliveryAddress,
        purchaseOrderSchedule,
        billingAddress,
        termOfPayment,
      }
    },
    mounted() {
      this.getDetail()
    },
    methods: {
      getDetail() {
        const itemString = localStorage.getItem("SummaryData")
        console.log(itemString)
        // Parse the string back to an object
        const item = JSON.parse(itemString)
        console.log(item)
        // Ensure that po_numbers exists and is a string before splitting
        if (item.po_numbers && typeof item.po_numbers === 'string') {
        this.items = item.po_numbers.split(",")
        } else {
        this.items = []
        }
      },
      print() {
        const userToken = this.$cookies.get("userToken")
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        }
        const item = JSON.parse(localStorage.getItem("podetail"))
        const id = item.purchase_order_id
        axios
          .get(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_PURCHASE_ORDER}${id}`,
            { headers },
          )
          .then(response => {
            if (response.data.success === true) {
              const url = `${process.env.VUE_APP_API_URL}temp/${response.data.data}`
              window.open(url, "_blank")
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Download Data failed",
                  icon: "CheckIcon",
                  variant: "danger",
                },
              })
            }
          })
      },
      getOptionLabel(option) {
        return (option && option.label) || ""
      },
      putItem(val) {
        if (this.chosens.length > 0) {
          let same = false
          this.chosens.map(elem => {
            if (elem.value === val.value) {
              same = true
            }
          })
          if (same === false) {
            let item = null
            this.items.map(elem => {
              if (elem.value === val.value) {
                item = { label: elem.label, value: elem.value, qty: 1 }
              }
            })
            this.chosens.push(item)
          }
        } else {
          let item = null
          this.items.map(elem => {
            if (elem.value === val.value) {
              item = { label: elem.label, value: elem.value, qty: 1 }
            }
          })

          this.chosens.push(item)
        }
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ""
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => obj.value !== val)
      },
      dateFormat(value, format = "MM/DD/YYYY") {
        return moment(String(value)).format(format)
      },
      dateSimple(value, format = "YYYY-MM-DD") {
        let dateRet = ""
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else {
          dateRet = null
        }
        return dateRet
      },
      previous() {
        this.$router.go(-1)
      },
      numberFormat(value) {
        return parseFloat(value).toFixed(2)
      },
      capitalizeWords(str) {
        // Split the string into words using spaces as the separator
        const words = str.split(" ")
        // Capitalize the first letter of each word and join them back together
        const capitalizedWords = words.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1)
        })
        // Join the capitalized words into a single string with spaces
        return capitalizedWords.join(" ")
      },
      numFormat(num) {
      // Convert the number to a string
      const numberString = String(num)
      // Split the string into integer and fractional parts
      const [integerPart, fractionalPart = ''] = numberString.split('.')
      // Add comma separators to the integer part
      const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      // Return the formatted number with the fractional part
      return fractionalPart ? `${integerWithCommas}.${fractionalPart}` : integerWithCommas
  },
   roundToTwoDecimals(num) {
      return (Math.round(num * 100) / 100).toFixed(2)
  },
    },
  }
  </script>